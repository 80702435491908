import axios from 'axios'

class ImosCampaignsService {
  uploadFiles (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data.file)

    return axios.post('/imos_campaigns/upload?dealer_name=' + data.dealerName + '&campaign_name=' + data.campaignName, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  getFiles () {
    return axios.get(
      '/imos_campaigns',
    )
  }

  deleteFile (fileId) {
    return axios.delete(
      '/imos_campaigns/' + fileId,
    )
  }
}

export default new ImosCampaignsService()
