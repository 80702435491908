<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="iMos Campaigns"
    />
    <template v-if="uploading">
      <v-progress-linear
        v-model="progress"
        color="light-blue"
        height="25"
        reactive
        striped
      >
        <strong>{{ progress }} %</strong>
      </v-progress-linear>
    </template>

    <v-row>
      <v-col>
          <v-btn
            color="primary"
            @click="addCampaign()"
          >
            Add Campaign
          </v-btn>
      </v-col>
    </v-row>    

    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Campaigns
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >       
            <template #item.image_url="{value}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    max-height="150"
                    height="100"
                    max-width="100"
                    :src=value
                    v-on="on"
                    @click="copyToClipboard(value)"                    
                  ></v-img>
                </template>
                <span>Copy Image URL to Clipboard</span>
              </v-tooltip>
            </template>
            <template #item.updated_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>            
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="editCampaign(item)"
              >
                mdi-pencil
              </v-icon>              
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showCampaignDialog"
      persistent
      max-width="600"
      @keydown.esc="showCampaignDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          iMos Campaign
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="currentCampaign.dealer_name"
            label="Dealer Name"
            required
            :rules="[v => !!v || 'Dealer name is required']"
            outlined
          ></v-text-field>
          
          <v-text-field
            v-model="currentCampaign.campaign_name"
            label="Campaign Name"
            required
            :rules="[v => !!v || 'Campaign name is required']"
            outlined
          ></v-text-field>

          <template>
            <v-file-input
              show-size
              label="Click to select image to upload."
              multiple
              @change="selectFile"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="file === undefined || file == '' || uploading"
            @click="handleFileUpload()"
          >
            Upload
          </v-btn>
          <v-btn
            @click="showCampaignDialog = false"
          >
            Cancel
          </v-btn>      
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>    
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ImosCampaignsService from '@/services/ImosCampaignsService'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'ImosCampaigns',
    components: {
      ErrorMessage,
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        showCampaignDialog: false,
        message: 'Copy These Text',
        dialog: false,
        items: [],
        files: [],
        search: '',
        loading: true,
        loadingMessage: 'Loading',
        showErrorDialog: false,        
        error: '',
        currentCampaign: {},        
        headers: [
          { text: 'Dealer', value: 'dealer_name' },
          { text: 'Campaign', value: 'campaign_name', align: 'center' },
          { text: 'Image', value: 'image_url', align: 'center' },
          { text: 'Last Updated', value: 'updated_at', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        file: '',
        progress: 0,
        uploading: false,
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId']),
    },
    watch: {
      accountId () {
        this.loadFiles()
      },
    },
    created () {
      this.loadFiles()
    },
    methods: {
      onCopy: function (e) {
        alert('You just copied: ' + e.text)
      },
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },      
      loadFiles: function () {
        this.items = []
        this.loading = true
        ImosCampaignsService.getFiles()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      copyToClipboard (item) {
        this.$copyText(item)
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this file?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          ImosCampaignsService.deleteFile(item.id)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
        }
      },
      handleFileUpload () {
        this.uploading = true
        this.progress = 1
        var numberFilesToUpload = this.file.length
        var numberFilesUploaded = 0
        for (const file of this.file) {
          var data = {
            file:         file,
            dealerName:   this.currentCampaign.dealer_name,
            campaignName: this.currentCampaign.campaign_name,
          }

          ImosCampaignsService.uploadFiles(data, (event) => {
          })
            .then((response) => {
              numberFilesUploaded += 1
              var progress = Math.round((100 * numberFilesUploaded) / numberFilesToUpload)
              this.progress = progress
              if (numberFilesUploaded === numberFilesToUpload) {
                this.progress = 0
                this.file = undefined
                this.uploading = false
                this.showCampaignDialog = false
                this.loadFiles()
              }
            })
            .catch(() => {
              this.progress = 0
              this.file = undefined
              this.uploading = false
            })
        }
      },

      selectFile (file) {
        this.progress = 0
        this.file = file
      },

      editCampaign(campaign) {
        this.currentCampaign = campaign
        this.showCampaignDialog = true
      },
      addCampaign() {
        this.currentCampaign = {}
        this.showCampaignDialog = true
      },      
    },
  }
</script>
